<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 mb-3">
    <h1 class="h2">Asset: <span *ngIf="asset">{{asset.alais === '' ? asset.serialNumber : asset.alais}}</span>
        <span *ngIf="asset && asset.parentAssetId"> | <a [href]="'/inventory/assets/' + asset.parentAssetId">Parent
                Asset: {{asset.parentAsset}}</a></span>
        <span *ngIf="asset && asset.bundleId"> | <a [href]="'/inventory/bundles/' + asset.bundleId">Parent
                Bundle ID: {{asset.bundleId}}</a></span>
    </h1>
</div>

<div *ngIf="loading">loading</div>

<div class="row scrollContainer" *ngIf="!loading">
    <div class="col-md-9 scrollColumn">
        <form class="needs-validation" novalidate="">
            <div class="row">
                <div class="flex-row">
                    <div>
                        <p class="lead">Status</p>
                        <p class="text-muted">The status is where the items is in it's lifecycle. If this asset has sub
                            assets, status changes will
                            cascade.</p>
                    </div>
                    <button
                        *ngIf="(asset.assetTypeId.toString() === waltAssetType.toString()) && (this.permissions.PullLogs > 0)"
                        mat-raised-button class="demo-button mb-3"
                        style="height:40px"
                        color="primary"
                        (click)="showLogsModal()"
                    >
                        Pull Logs
                    </button>
                </div>
                <hr />
                <div class="col-md-8 mb-9">
                    <mat-form-field appearance="fill" style="width:100%">
                        <mat-label>Status</mat-label>
                        <mat-select [(ngModel)]="asset.status" [disabled]="asset.parentAssetId || asset.bundleId || (asset.orderId !== null && ['Packing','In Transit'].includes(asset.status))"
                            [formControl]="statusControl" name="status" ngDefaultControl>
                            <mat-option [value]="assetStatuses.Available" *ngIf="assetId !== 0">
                                Available | <small>Asset is ready for use.</small>
                            </mat-option>
                            <mat-option [value]="assetStatuses.CheckedOut" *ngIf="assetId !== 0">
                                Checked Out | <small>Asset is checked out to a specific
                                    person.</small>
                            </mat-option>
                            <mat-option [value]="assetStatuses.Decommissioned" *ngIf="assetId !== 0">
                                Decommissioned | <small>Asset is no longer usable and
                                    will be discarded.</small>
                            </mat-option>
                            <mat-option [value]="assetStatuses.IncomingForecast">
                                Incoming Forecast | <small>Asset is being manufactured and shipped</small>
                            </mat-option>
                            <mat-option [value]="assetStatuses.Maintenance" *ngIf="assetId !== 0">
                                Maintenance | <small>Asset requires maintenance before
                                    it is ready for use.</small>
                            </mat-option>
                            <mat-option [value]="assetStatuses.Provisioning">
                                Provisioning | <small>Asset requires verification before
                                    it is ready for use</small>
                            </mat-option>
                        </mat-select>
                        <mat-hint *ngIf="asset.parentAssetId"> Inherited from {{asset.parentAsset}}</mat-hint>
                        <mat-hint *ngIf="asset.bundleId"> Inherited from bundle</mat-hint>
                        <mat-error *ngIf="statusControl.invalid">Status Required</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-2 mb-3">
                    <button mat-button *ngIf="asset.orderId !== null" color="primary"
                        [routerLink]="'/inventory/fulfillments'" [queryParams]="{'orderId': asset.orderId}">Order ID:
                        {{asset.orderId}}</button>
                </div>
                <div class="col-md-2 mb-3">
                    <button mat-button *ngIf="asset.fulfillmentId !== null" color="primary"
                        [routerLink]="'/inventory/fulfillments/' + asset.fulfillmentId">Fulfillment ID:
                        {{asset.fulfillmentId}}</button>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3 mb-3" *ngIf="asset.status === assetStatuses.IncomingForecast">
                    <mat-form-field appearance="fill" style="width:100%">
                        <mat-label>Expected Delivery Date</mat-label>
                        <input matInput name="expectedDeliveryDate" [matDatepicker]="expectedDeliveryDatePicker"
                            [(ngModel)]="asset.expectedDeliveryDate">
                        <mat-datepicker-toggle matSuffix [for]="expectedDeliveryDatePicker"></mat-datepicker-toggle>
                        <mat-datepicker #expectedDeliveryDatePicker></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div>
                    <p class="lead">General Information</p>
                    <p class="text-muted">General information about the asset.</p>
                </div>
                <hr />
                <div class="col-md-4 mb-3">
                    <mat-form-field appearance="fill" style="width:100%">
                        <mat-label>Serial Number</mat-label>
                        <input matInput placeholder="Serial Number" name="serialNumber" [(ngModel)]="asset.serialNumber"
                            [formControl]="serialNumberControl">
                        <mat-error *ngIf="serialNumberControl.invalid">Serial Number Required</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-4 mb-3">
                    <mat-form-field appearance="fill" style="width:100%">
                        <mat-label>Alais</mat-label>
                        <input matInput placeholder="Alais" name="alais" [(ngModel)]="asset.alais">
                    </mat-form-field>
                </div>
                <div class="col-md-2 mb-3">
                    <mat-form-field appearance="fill" style="width:100%">
                        <mat-label>Cost</mat-label>
                        <input matInput placeholder="Cost" name="cost" [(ngModel)]="asset.cost" type="number" min="0.00"
                            max="10000.00" step="0.01" (keyup)="validateNumber($event)"
                            class="tempFixUntilMatTextPrefix">
                        <!-- TODO(mdc-migration): use matTextPrefix instead. -->
                        <span matPrefix>$ &nbsp;</span>
                    </mat-form-field>
                </div>
                <div class="col-md-2 mb-3">
                    <mat-form-field style="width:100%" *ngIf="assetId !== 0" appearance="fill">
                        <mat-label>Asset ID</mat-label>
                        <input matInput placeholder="Asset ID" name="assetId" [(ngModel)]="asset.id" disabled>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col-md-5 mb-3">
                    <mat-form-field appearance="fill" style="width:100%">
                        <mat-label>Model</mat-label>
                        <mat-select [(value)]="asset.assetModelId" [formControl]="assetModelControl"
                            name="assetModelId">
                            <mat-option *ngFor="let model of assetModels" [value]="model.id"
                                (click)="asset.assetModelDescription = model.description">
                                {{model.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="assetModelControl.invalid">Model Required</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-7 mb-3">
                    <mat-form-field style="width:100%" appearance="fill">
                        <mat-label>Model Description</mat-label>
                        <textarea matInput placeholder="Description" name="description"
                            [(ngModel)]="asset.assetModelDescription" disabled></textarea>
                        <mat-hint> Inherited from Model</mat-hint>
                    </mat-form-field>
                </div>
            </div>
            <hr />
            <div class="row"
                *ngIf="!asset.parentAssetId && asset.status !== assetStatuses.Decommissioned && asset.status !== assetStatuses.IncomingForecast">
                <div class="d-flex flex-row justify-content-between gap-3">
                    <div class="d-flex flex-column justify-content-start align-items-start">
                        <p class="lead">Assignment and Location</p>
                        <p class="text-muted">Where the asset is and/or who is responsible for it.</p>
                    </div>
                    <div *ngIf="!loading" class="d-flex flex-row justify-content-start gap-3">
                        <button *ngIf="isWaltWifiDisabled" mat-raised-button class="demo-button" color="primary" (click)="isEnableDisableWaltWifisModalOpen=true">
                            Re-enable WiFi
                        </button>
                        <button *ngIf="!isWaltWifiDisabled" mat-raised-button class="demo-button" color="primary" (click)="isEnableDisableWaltWifisModalOpen=true">
                            Temporarily Disable WiFi
                        </button>
                        <button
                            mat-raised-button
                            class="demo-button"
                            color="primary"
                            [disabled]="isDeletingSavedWifis"
                            (click)="isDeleteSavedWifiModalOpen=true">
                            Delete All Saved Wifi Networks
                        </button>
                    </div>
                </div>
                <div class="col-md-6 mb-3"
                    *ngIf="[assetStatuses.Available,assetStatuses.Provisioning, assetStatuses.Maintenance,assetStatuses.IncomingForecast ,assetStatuses.Packing].includes(asset.status)">
                    <mat-form-field appearance="fill" style="width:100%">
                        <mat-label>Warehouse</mat-label>
                        <mat-select [disabled]="asset.parentAssetId || asset.bundleId" [formControl]="warehouseControl"
                            [(value)]="asset.warehouseId" name="warehouseId"
                            [disabled]="asset.orderId !== null && ['Packing'].includes(asset.status)">
                            <mat-option *ngFor="let warehouse of warehouses" [value]="warehouse.id">
                                {{warehouse.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error>Warehouse Required</mat-error>
                        <mat-hint *ngIf="asset.parentAssetId"> Inherited from {{asset.parentAsset}}</mat-hint>
                        <mat-hint *ngIf="asset.bundleId"> Inherited from bundle</mat-hint>
                    </mat-form-field>
                </div>
                <div class="col-md-6 mb-3" *ngIf="asset.status === assetStatuses.Available">
                    <mat-form-field style="width:100%" appearance="fill">
                        <mat-label>Warehouse Location</mat-label>
                        <input matInput placeholder="Warehouse Location" name="warehouseLocation"
                            [(ngModel)]="asset.warehouseLocation" [formControl]="warehouseLocationControl">
                        <mat-error *ngIf="warehouseLocationControl.invalid">Warehouse Location Required</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-6 mb-3"
                    *ngIf="asset.status === assetStatuses.Maintenance || asset.status === assetStatuses.CheckedOut">
                    <app-autocomplete [options]="people" name="personId" [value]="asset.personId"
                        label="Person Assigned To" [disabled]="asset.parentAssetId || asset.bundleId"
                        [disabledValue]="asset.assignedToPersonName" (optionChange)="asset.personId = $event.id"
                        [filterLimit]="100" [hint]="asset.ParentAsset ? 'Inherited from ' + asset.parentAsset : ''"
                        [control]="personControl" displayKey="displayName">
                    </app-autocomplete>
                </div>
                <div class="col-md-6 mb-3" *ngIf="asset.status === assetStatuses.CheckedOut">
                    <app-autocomplete [options]="sites" name="siteId" [value]="asset.siteId"
                        label="Company Site the asset is assigned to" [disabled]="asset.parentAssetId || asset.bundleId"
                        (optionChange)="asset.siteId = $event.id" [filterLimit]="100"
                        [hint]="asset.ParentAsset ? 'Inherited from ' + asset.parentAsset : ''" [control]="siteControl">
                    </app-autocomplete>
                </div>
            </div>
            <div class="row" style="padding-bottom:10px;" *ngIf="asset.assetTypeId.toString() === waltAssetType.toString()">
                <div class="col-md-3 mb-3">
                    <mat-form-field style="width:100%" appearance="fill">
                        <mat-label>Last Reported</mat-label>
                        <input matInput placeholder="Last Reported" name="locationUpdated"
                            [ngModel]="asset.locationUpdated | date: 'MM/dd/yyyy hh:mm a'" disabled>
                    </mat-form-field>
                </div>
                <div class="col-md-3 mb-3">
                    <mat-form-field style="width:100%" appearance="fill">
                        <mat-label>Network</mat-label>
                        <input matInput placeholder="Network" name="networkType"
                            [ngModel]="asset.networkType" disabled>
                    </mat-form-field>
                </div>
                <div class="col-md-3 mb-3">
                    <mat-form-field style="width:100%" appearance="fill">
                        <mat-label>Version</mat-label>
                        <input matInput name="version"
                            [ngModel]="asset.version" disabled>
                    </mat-form-field>
                </div>
                <div class="col-md-3 mb-3" *ngIf="asset.simOneCarrier">
                    <mat-form-field style="width:100%" appearance="fill">
                        <mat-label>Sim One Carrier</mat-label>
                        <input matInput name="simOneCarrier"
                            [ngModel]="asset.simOneCarrier" disabled>
                    </mat-form-field>
                </div>
                <div class="col-md-3 mb-3" *ngIf="asset.simOneImei">
                    <mat-form-field style="width:100%" appearance="fill">
                        <mat-label>Sim One IMEI</mat-label>
                        <input matInput name="simOneImei"
                            [ngModel]="asset.simOneImei" disabled>
                    </mat-form-field>
                </div>
                <div class="col-md-3 mb-3" *ngIf="asset.simOneIccid">
                    <mat-form-field style="width:100%" appearance="fill">
                        <mat-label>Sim One ICCID</mat-label>
                        <input matInput name="simOneIccid"
                            [ngModel]="asset.simOneIccid" disabled>
                    </mat-form-field>
                </div>
                <div class="col-md-3 mb-3" *ngIf="asset.simTwoCarrier">
                    <mat-form-field style="width:100%" appearance="fill">
                        <mat-label>Sim Two Carrier</mat-label>
                        <input matInput name="simTwoCarrier"
                            [ngModel]="asset.simTwoCarrier" disabled>
                    </mat-form-field>
                </div>
                <div class="col-md-3 mb-3" *ngIf="asset.simTwoImei">
                    <mat-form-field style="width:100%" appearance="fill">
                        <mat-label>Sim Two IMEI</mat-label>
                        <input matInput name="simTwoImei"
                            [ngModel]="asset.simTwoImei" disabled>
                    </mat-form-field>
                </div>
                <div class="col-md-3 mb-3" *ngIf="asset.simTwoIccid">
                    <mat-form-field style="width:100%" appearance="fill">
                        <mat-label>Sim Two ICCID</mat-label>
                        <input matInput name="simTwoIccid"
                            [ngModel]="asset.simTwoIccid" disabled>
                    </mat-form-field>
                </div>
                <div class="col-md-3 mb-3" *ngIf="asset.currentNetworkSsid">
                    <mat-form-field style="width:100%" appearance="fill">
                        <mat-label>Current Network</mat-label>
                        <input matInput name="currentNetworkSsid"
                            [ngModel]="asset.currentNetworkSsid" disabled>
                    </mat-form-field>
                </div>
                <div class="col-md-3 mb-3" *ngIf="asset.currentNetworkIp">
                    <mat-form-field style="width:100%" appearance="fill">
                        <mat-label>Current Network IP</mat-label>
                        <input matInput name="currentNetworkIp"
                            [ngModel]="asset.currentNetworkIp" disabled>
                    </mat-form-field>
                </div>
                <div class="col-md-3 mb-3" *ngIf="asset.currentNetworkMac">
                    <mat-form-field style="width:100%" appearance="fill">
                        <mat-label>Current Network MAC</mat-label>
                        <input matInput name="currentNetworkMac"
                            [ngModel]="asset.currentNetworkMac.toUpperCase()" disabled>
                    </mat-form-field>
                </div>
                <div class="col-md-3 mb-3" *ngIf="asset.macAddress">
                    <mat-form-field style="width:100%" appearance="fill">
                        <mat-label>MAC Address</mat-label>
                        <input matInput name="macAddress"
                            [ngModel]="asset.macAddress" disabled>
                    </mat-form-field>
                </div>
                <div class="col-md-3 mb-3" *ngIf="asset.btAddress">
                    <mat-form-field style="width:100%" appearance="fill">
                        <mat-label>Bluetooth Address</mat-label>
                        <input matInput name="btAddress"
                            [ngModel]="asset.btAddress" disabled>
                    </mat-form-field>
                </div>
            </div>
            <div class="row" style="padding-bottom:10px;" *ngIf="asset.latitude">
                <google-map *ngIf="!loading" [height]="200" [width]="null" [center]="{ lat: +asset.longitude, lng: +asset.latitude }">
                    <map-marker
                        [position]="{ lat: +asset.longitude, lng: +asset.latitude }"
                        [title]="asset.serialNumber"
                    >
                    </map-marker>
                </google-map>
            </div>
            <div class="row" *ngIf="!asset.parentAssetId">
                <div>
                    <p class="lead">Sub Assets</p>
                    <p class="text-muted">Any assets that are componets of this asset can be added as a sub asset.
                        Sub assets will inherit status, assignment and location from their parent.</p>
                </div>
                <hr />
                <div class="col-md-12 mb-3">
                    <app-chip label="Sub Assets" [options]="allAssets" (selectionChanged)="subAssetChanges($event)"
                        [selectedOptions]="subAssets" (chipClicked)="goToSubAsset($event)"
                        [disabled]="asset.orderId !== null && [assetStatuses.Packing, assetStatuses.InTransit].includes(asset.status)">
                    </app-chip>
                </div>

            </div>
            <div class="row">
                <div>
                    <p class="lead">Device Identifiers</p>
                    <p class="text-muted">Optional properties specifically for devices.</p>
                </div>
                <div class="row">
                    <div class="col-md-4 mb-4">
                        <mat-form-field style="width:100%" appearance="fill">
                            <mat-label>IMEI One</mat-label>
                            <input matInput placeholder="IMEI One" name="imeiOne" [(ngModel)]="asset.imeiOne">
                        </mat-form-field>
                    </div>
                    <div class="col-md-4 mb-4">
                        <mat-form-field style="width:100%" appearance="fill">
                            <mat-label>IMEI Two</mat-label>
                            <input matInput placeholder="IMEI Two" name="imeiTwo" [(ngModel)]="asset.imeiTwo">
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4 mb-4">
                        <mat-form-field style="width:100%" appearance="fill">
                            <mat-label>eID One</mat-label>
                            <input matInput placeholder="eID One" name="eIdOne" [(ngModel)]="asset.eIdOne">
                        </mat-form-field>
                    </div>
                    <div class="col-md-4 mb-4">
                        <mat-form-field style="width:100%" appearance="fill">
                            <mat-label>eID Two</mat-label>
                            <input matInput placeholder="eID Two" name="eIdTwo" [(ngModel)]="asset.eIdTwo">
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-md-4 mb-4">
                    <mat-form-field style="width:100%" appearance="fill">
                        <mat-label>Bluetooth MAC Address</mat-label>
                        <input matInput placeholder="Bluetooth MAC Address" name="bluetoothMac"
                            [(ngModel)]="asset.bluetoothMac">
                    </mat-form-field>
                </div>
                <div class="col-md-4 mb-4">
                    <mat-form-field style="width:100%" appearance="fill">
                        <mat-label>WIFI MAC Address</mat-label>
                        <input matInput placeholder="WIFI MAC Address" name="wifiMac" [(ngModel)]="asset.wifiMac">
                    </mat-form-field>
                </div>
                <div class="col-md-4 mb-4">
                    <mat-form-field style="width:100%" appearance="fill">
                        <mat-label>Hardwire MAC Address</mat-label>
                        <input matInput placeholder="Hardwire MAC Address" name="hardwireMac"
                            [(ngModel)]="asset.hardwireMac">
                    </mat-form-field>
                </div>
                <hr>
            </div>
            <div class="row" *ngIf="asset.assetTypeId === assetTypes.roip && assetId !== 0">
                <div>
                    <p class="lead">Radio Configuration</p>
                    <p class="text-muted">Set radio ports and encryption keys.</p>
                </div>
                <div class="col-md-6 mb-4">
                    <mat-form-field style="width:100%" appearance="fill">
                        <mat-label>Radio 1 Port</mat-label>
                        <input readonly matInput placeholder="Radio 1 Port" name="radio1Port" [(ngModel)]="asset.radio1Port" type="number">
                    </mat-form-field>
                </div>
                <div class="col-md-6 mb-4">
                    <mat-form-field style="width:100%" appearance="fill">
                        <mat-label>Radio 2 Port</mat-label>
                        <input readonly matInput placeholder="Radio 2 Port" name="radio2Port" [(ngModel)]="asset.radio2Port" type="number">
                    </mat-form-field>
                </div>
                <div class="col-md-6 mb-4">
                    <mat-form-field style="width:100%" appearance="fill">
                        <mat-label>Radio 1 Encryption Key</mat-label>
                        <input readonly matInput placeholder="Radio 1 Encryption Key" name="radio1EncryptionKey"
                            [(ngModel)]="asset.radio1EncryptionKey">
                    </mat-form-field>
                </div>
                <div class="col-md-6 mb-4">
                    <mat-form-field style="width:100%" appearance="fill">
                        <mat-label>Radio 2 Encryption Key</mat-label>
                        <input readonly matInput placeholder="Radio 2 Encryption Key" name="radio2EncryptionKey" [(ngModel)]="asset.radio2EncryptionKey">
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col-12 mb-3">
                    <div class="d-flex flex-row justify-content-between">
                        <div class="d-flex flex-row justify-content-start gap-3">
                            <button mat-raised-button class="demo-button" color="primary" (click)="save()"
                                [disabled]="serialNumberControl.invalid 
                                    || asset.status === null 
                                    || ([assetStatuses.Available, assetStatuses.Provisioning, assetStatuses.Maintenance, assetStatuses.IncomingForecast].includes(asset.status) && warehouseControl.invalid) 
                                    || ([assetStatuses.Maintenance].includes(asset.status) && personControl.invalid) 
                                    || ([assetStatuses.CheckedOut].includes(asset.status) && siteControl.invalid)
                                    || assetModelControl.invalid
                                    || ([assetStatuses.Available].includes(asset.status) && warehouseLocationControl.invalid)">
                                Save
                            </button>
                            <button mat-raised-button class="demo-button"
                                [routerLink]="'/inventory/assetTypes/assets/' + asset.assetTypeId">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="col-md-3">
        <app-activity [id]="assetId" [table]="'asset'"></app-activity>
    </div>
</div>

<app-send-logs-modal
    [id]="asset.id"
    [logsType]="logsType.Walt"
    *ngIf="logsModalOpen"
    (allDone)="logsModalOpen = false"
    (loadingChanged)="loading = $event"
/>

<app-enable-disable-walt-wifis-modal
    [open]="isEnableDisableWaltWifisModalOpen"
    [waltId]="assetId"
    [disabled]="!waltWifiDisabledDate"
    (submitEvent)="handleEnableDisableWaltWifiSubmitted()"
    (errorEvent)="isEnableDisableWaltWifisModalOpen=false"
    (closeEvent)="isEnableDisableWaltWifisModalOpen=false"
    (loadingChanged)="isEnableDisableWaltWifisModalOpen=$event"
/>

<app-delete-walt-saved-wifis-modal
    [open]="isDeleteSavedWifiModalOpen"
    [waltId]="assetId"
    (errorEvent)="isDeleteSavedWifiModalOpen=false"
    (closeEvent)="isDeleteSavedWifiModalOpen=false"
    (loadingChanged)="isDeletingSavedWifis=$event"
/>