export class Asset {
    id?: number;
    alais?: string;
    serialNumber?: string;
    assetTypeId?: number;
    status?: string;
    cost?: number;
    assetModelId?: number;
    bundleId?: number;
    orderId?: number;
    fulfillmentId?: number;
    warehouseId?: number;
    warehouseLocation?: string;
    personId?: number;
    siteId?: number;
    instanceId?: number;
    imeiOne?: number;
    eIdOne?: number;
    imeiTwo?: number;
    eIdTwo?: number;
    bluetoothMac?: string;
    wifiMac?: string;
    hardwireMac?: string;
    expectedDeliveryDate?: string;
}

export enum AssetStatuses {
    Available = 'Available',
    CheckedOut = 'Checked Out',
    Decommissioned = 'Decommissioned',
    IncomingForecast = 'Incoming Forecast',
    Maintenance = 'Maintenance',
    Provisioning = 'Provisioning',
    Packing = 'Packing',
    InTransit = 'In Transit',
}

export interface AssetFilter {
    status: string[];
    statusDetail: string[];
    company: string[];
    name: string;
    assetModel: string[];
    serialNumber: string;
    version: string;
    locationUpdated: Date[];
    globalSearch: string;
}